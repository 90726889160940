import { DataService } from './data/services/data/data.service';
import {
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  constructor(private dataService: DataService) {
    // This is intentional
  }
  ngOnInit(): void {
    this.dataService.setActivity(new Date().getTime())
  }

  listenerEvent(): void {
    this.dataService.setActivity(new Date().getTime())
  }

}
