<div class="bc-table-container">
    <div class="bc-table-header-top">
        <h6 class="bc-table-header-top-title">{{title}}</h6>
        <div class="bc-table-header-top-right-container">
            <bcs-button
                *ngIf="view"
                Label="Descargar documentación"
                TypeButton="secondary"
                name="search"
                (click)="downloadAll()"
            ></bcs-button>
            <div class="bc-table-actions-container">
                <div *ngIf="isPaginator" class="bc-table-paginator-container">
                    <mat-paginator (page)="handlePageEvent($event)" [pageIndex]="page.number"
                        [length]="page.totalElements" [pageSizeOptions]="[5, 10]"
                        aria-label="Select page"></mat-paginator>
                </div>
            </div>
        </div>
    </div>
    <div class="bc-table-content">
        <table class="bc-table" [ngClass]="{'bc-table-nowrap' : isWrap}">
            <caption></caption>
            <thead>
                <tr>
                    <th class="sticky-left" *ngIf="!readOnly && isCheck"> </th>
                    <th *ngFor="let col of columns; let i = index" [ngClass]="{'sticky-left':col.sticky }">{{
                        col.headerTitle}}</th>
                    <th class="sticky-right"> </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of data; let i = index">
                    <td class="sticky-left" *ngIf="!readOnly && isCheck">
                        <mat-checkbox *ngIf="row.editable == true && i !== editable"
                            [(ngModel)]="row.includeApproval"></mat-checkbox>
                    </td>
                    <td *ngFor="let col of columns; let j = index" [ngClass]="{'sticky-left':col.sticky }">
                        <ng-container *ngIf="i !== editable">
                            <ng-container *ngIf="!col.edited">
                                <ng-container *ngIf="col.isOpen == false">
                                    <ng-container
                                        *ngIf="!col.isCurrency && col.type!='date'">{{row[col.field]}}</ng-container>
                                    <ng-container
                                        *ngIf="col.isCurrency">{{row[col.field] | number:".2-2" }}</ng-container>
                                    <ng-container
                                        *ngIf="col.type=='date'">{{row[col.field] | date : 'dd/MM/yyyy'
                                        }}</ng-container>
                                </ng-container>
                                <ng-container *ngIf="col.isOpen == true">
                                    <ng-container *ngIf="validateIsOpenFunction(row) == false">
                                        <ng-container
                                            *ngIf="!col.isCurrency && col.type!='date'">{{row[col.field]}}</ng-container>
                                        <ng-container
                                            *ngIf="col.isCurrency">{{row[col.field] | number:".2-2" }}</ng-container>
                                        <ng-container
                                            *ngIf="col.type=='date'">{{row[col.field] | date : 'dd/MM/yyyy'
                                            }}</ng-container>

                                    </ng-container>
                                    <ng-container *ngIf="validateIsOpenFunction(row) == true">
                                        <a class="link" (click)="selected( row )"> {{row[col.field]}} </a>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="col.edited">
                                <ng-container
                                    *ngIf="!col.isCurrency && col.type!='date'">{{row[col.field]}}</ng-container>
                                <ng-container
                                    *ngIf="col.isCurrency">{{row[col.field] | number:".2-2" }}</ng-container>
                                <ng-container
                                    *ngIf="col.type=='date'">{{row[col.field] | date : 'dd/MM/yyyy'
                                    }}</ng-container>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="i === editable">
                            <ng-container *ngIf="(col.field=='valorImpuestosUSD' && row.regimenImportacion.toUpperCase() == 'LARGO PLAZO')
                                || ((col.field=='estado' || col.field=='formaFinalizacion') && row.regimenImportacion.toUpperCase() == 'LARGO PLAZO')">
                                <ng-container
                                    *ngIf="!col.isCurrency && col.type!='date'">{{row[col.field]}}</ng-container>
                                <ng-container
                                    *ngIf="col.isCurrency">{{row[col.field] | number:".2-2" }}</ng-container>
                                <ng-container
                                    *ngIf="col.type=='date'">{{row[col.field] | date : 'dd/MM/yyyy'
                                    }}</ng-container>
                            </ng-container>
                            <ng-container *ngIf="!((col.field=='valorImpuestosUSD' && row.regimenImportacion.toUpperCase() == 'LARGO PLAZO')
                            || ((col.field=='estado' || col.field=='formaFinalizacion') && row.regimenImportacion.toUpperCase() == 'LARGO PLAZO'))">
                                <ng-container *ngIf="!col.edited">
                                    <ng-container
                                        *ngIf="!col.isCurrency && col.type!='date'">{{row[col.field]}}</ng-container>
                                    <ng-container
                                        *ngIf="col.isCurrency">{{row[col.field] | number:".2-2" }}</ng-container>
                                    <ng-container
                                        *ngIf="col.type=='date'">{{row[col.field] | date : 'dd/MM/yyyy'
                                        }}</ng-container>
                                </ng-container>
                                <ng-container *ngIf="col.edited">
                                    <mat-form-field class="example-form-field">
                                        <ng-container *ngIf="col.isCurrency">
                                            <input autocomplete="off" matInput [disabled]="!col.edited" type="format" currencyMask
                                                [options]="optionCurrencyMask" [(ngModel)]="row[col.field]"
                                                [ngModelOptions]="{standalone: true}" [required]="col.required"
                                                (ngModelChange)="onChange($event,row)">
                                        </ng-container>
                                        <ng-container *ngIf="!col.isCurrency">
                                            <ng-container *ngIf="col.select != undefined">
                                                <mat-select [(ngModel)]="row[col.field]">
                                                    <mat-option *ngFor="let item of col.select" [value]="item.Id">
                                                        {{item.Description}}
                                                    </mat-option>
                                                </mat-select>
                                            </ng-container>

                                            <ng-container *ngIf="col.select == undefined">
                                                <input autocomplete="off" matInput [disabled]="!col.edited" [type]="col.type"
                                                    [(ngModel)]="row[col.field]" [ngModelOptions]="{standalone: true}"
                                                    [required]="col.required" (ngModelChange)="onChange($event,row)">
                                            </ng-container>
                                        </ng-container>
                                    </mat-form-field>
                                    <mat-error *ngIf="col.required && isError(row[col.field])">
                                        <strong>requerido</strong>
                                    </mat-error>
                                </ng-container>
                            </ng-container>
                        </ng-container>

                    </td>
                    <td class="personalized-td-buttons sticky-right min-width"
                        *ngIf="!readOnly && row.editable == true">
                        <button mat-icon-button (click)="edit(i,row)">
                            <bc-icon *ngIf="editable !== i && isEdit" class="bc-icon bc-sm">edit</bc-icon>
                            <bc-icon *ngIf="editable === i && isEdit" class="bc-icon bc-sm">save</bc-icon>
                        </button>
                        <button mat-icon-button (click)="cancel()" *ngIf="i === editable">
                            <bc-icon class="bc-icon bc-sm">remove</bc-icon>
                        </button>
                        <button mat-icon-button *ngIf="i !== editable && isDocument" (click)="document(row)">
                            <bc-icon class="bc-icon bc-sm">view</bc-icon>
                        </button>
                        <button mat-icon-button *ngIf="i !== editable && isDelete" (click)="delete(row)">
                            <bc-icon class="bc-icon bc-sm">erase</bc-icon>
                        </button>
                    </td>
                    <td class="personalized-td-buttons sticky-right min-width"
                        *ngIf="row.editable == false && view">
                        <button mat-icon-button *ngIf="i !== editable && isDocument" (click)="document(row)">
                            <bc-icon class="bc-icon bc-sm">view</bc-icon>
                        </button>
                    </td>
                    <td class="personalized-td-buttons sticky-right" *ngIf="!(!readOnly && row.editable == true)">
                        <button mat-icon-button *ngIf="isDeleteBtn" (click)="delete(row)">
                            <mat-icon>delete_outline</mat-icon>
                        </button>
                        <button mat-icon-button *ngIf="isDownload" (click)="download(row)">
                            <mat-icon>download</mat-icon>
                        </button>
                </tr>
            </tbody>
        </table>
    </div>
</div>
