import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IColumn } from '../../data/interfaces/icolumn';
import { IPage } from '../../data/interfaces/igeneric-response';
import { PageDto } from '../../data/models/page.dto';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { OptionsMask } from '@constants/options-mask';

@Component({
  selector: 'app-table-temporary',
  templateUrl: './table-temporary.component.html',
  styleUrls: ['./table-temporary.component.scss']
})
export class TableTemporaryComponent implements OnInit {
  @Input() data: any[] = [];
  @Input() title: string = 'Title';
  @Input() importNumber!: string;
  @Input() columns: IColumn[] = [];
  @Input() readOnly: boolean = false;
  @Input() isPaginator: boolean = true;
  @Input() isDelete: boolean = true;
  @Input() isDeleteBtn: boolean = false;
  @Input() isDownload: boolean = false;
  @Input() isDocument: boolean = false;
  @Input() view: boolean = false;
  @Input() isCheck: boolean = false;
  @Input() isWrap: boolean = false;
  @Input() validateRow!: (value: any) => boolean;
  @Input() validateIsOpen!: (value: any) => boolean;
  @Input() page: IPage = Object.assign(this, new PageDto());
  @Input() isEdit: boolean = true;

  editable: number = -1;

  @Output() openFunction = new EventEmitter<any>();
  @Output() PageChange = new EventEmitter<any>();
  @Output() UpdateRow = new EventEmitter<any>();
  @Output() CancelRow = new EventEmitter();
  @Output() DeleteRow = new EventEmitter<any>();
  @Output() DownloadRow = new EventEmitter<any>();
  @Output() DownloadAllRow = new EventEmitter<any>();
  @Output() DocumentRow = new EventEmitter<any>();
  @Output() onChangeEvent = new EventEmitter<any>();
  displayedColumns: string[] = this.columns.map((col) => col.field);
  optionCurrencyMask: any = OptionsMask.decimals;
  constructor(
    public _MatPaginatorIntl: MatPaginatorIntl
  ) {
    this._MatPaginatorIntl.itemsPerPageLabel = 'Líneas por página';
    // This is intentional
  }
  ngOnInit(): void {
    // This is intentional
  }
  isError(value: any): boolean {
    let isInputError = false;
    if (!value) {
      isInputError = true;
    }
    return isInputError;
  }
  document(row: any) {
    if (this.DocumentRow) {
      this.DocumentRow.emit(row);
    }
  }
  delete(row: any) {
    if (this.DeleteRow) {
      this.DeleteRow.emit(row);
    }
  }
  download(row: any) {
    if (this.DownloadRow) {
      this.DownloadRow.emit(row);
    }
  }
  downloadAll() {
    if (this.DownloadAllRow) {
      this.DownloadAllRow.emit();
    }
  }
  edit(i: number, row: any) {
    if (this.editable !== i) {
      this.editable = i;
    } else if (this.UpdateRow) {
      const isValid = this.validateRow(row);
      if (!isValid) {
        return;
      }
      this.editable = -1;
      this.UpdateRow.emit(row);
    }
  }
  onChange(event: any, row: any) {
    if (this.onChangeEvent) { this.onChangeEvent.emit(row); }
  }
  selected(row: any) {
    if (this.openFunction) {
      this.openFunction.emit(row);
    }
  }
  cancel() {
    this.editable = -1;
    if (this.CancelRow) this.CancelRow.emit();
  }
  handlePageEvent(event: any): void {
    this.page.number = Number.parseInt(event?.pageIndex);
    this.page.size = Number.parseInt(event?.pageSize);
    if (this.PageChange) {
      this.PageChange.emit(event);
    }
  }
  validateIsOpenFunction(row: any): boolean {
    let result = true;
    if (this.validateIsOpen) result = this.validateIsOpen(row) ?? true;
    return result;
  }
}
